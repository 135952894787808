@import "./mixins";
#home-section {
  position: relative;
  min-height: 100vh;
  height: 100%;
  .overlay-1 {
    background: rgba(0, 0, 0, .3);
    content: '';
    display: block;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    // z-index: 1;
  }
  .overlay-2 {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    content: '';
    display: block;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    // z-index: 1;
  }
  #video-slider {
    min-height: 100vh;
    height: 100%;
  }
  #info-container {
    position: absolute;
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    left: 0;
    top: 0;
    margin-top: 5%;
    padding: 10px;
    @include my_bp(sm) {
      padding: 0 5rem;
    }
    h1 {
      font-size: 30px;
      @include my_bp(sm) {
        font-size: 50px;
      }
    }
    h2 {
      font-size: 30px;
      font-weight: 800;
      line-height: 1;
      @include my_bp(sm) {
        font-size: 70px;
      }
    }
    p {
      text-align: justify;
      font-size: .9rem;
      @include my_bp(sm) {
        font-size: 1rem;
      }
    }
    .header-name {
      width: 100%;
    }
    .header-links {
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 0;
      border-top: 2px solid white;
      border-bottom: 2px solid white;
      @include my_bp(sm) {
        flex-direction: row;
        justify-content: space-evenly;
      }
      a {
        color: white;
        font-size: 20px;
        display: block;
        text-transform: uppercase;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .header-videos {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 10px;
      margin-top: 25px;
      flex-wrap: wrap;
      .video-item {
        background: #818181;
        background-size: cover;
        color: white;
        height: 100px;
        width: 49%;
        display: flex;
        align-items: flex-end;
        position: relative;
        @include my_bp(sm) {
          height: 150px;
          padding-left: 1rem;
          padding-bottom: 1rem;
          width: 24%;
        }
        &:hover {
          text-decoration: none;
          &:before {
            background: rgba(0, 0, 0, 0);
          }
        }
        &:before {
          background: rgba(0, 0, 0, .3);
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: all 1s ease;
        }
        &.ciid {
          background-image: url('../img/ciid.png');
        }
        &.fablab {
          background-image: url('../img/fablab.png');
        }
        &.unity {
          background-image: url('../img/unity.png');
        }
        &.gic {
          background-image: url('../img/gic.jpg');
        }
        .title {
          display: flex;
          position: absolute;
          &:before {
            content: "";
            background: url('../img/play_btn.svg');
            width: 25px;
            height: 25px;
            background-size: contain;
            background-repeat: no-repeat;
            margin: .5rem;
            @include my_bp(sm) {
              width: 50px;
              height: 50px;
            }
          }
          h3 {
            text-transform: uppercase;
            font-weight: 700;
            margin: 0;
            font-size: 1rem;
            @include my_bp(sm) {
              font-size: 1.75rem;
            }
          }
          h4 {
            text-transform: uppercase;
            font-weight: 200;
            font-size: .75rem;
            @include my_bp(sm) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

#apply {
  padding-top: 60px;
  padding-bottom: 20px;
  .button {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 600;
    padding: 15px 25px;
    background: #181717;
    border-radius: 20px;
    color: white;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
  }
}

#partners {
  padding-bottom: 60px;
  .col-sm-25 {
    width: 100%;
    @include my_bp(sm) {
      width: 29%;
    }
  }
  h5 {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: .85rem;
    text-transform: uppercase;
    color: #707070;
  }
  a {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: .85rem;
    text-transform: uppercase;
    color: #707070;
  }
  .content {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .item {
    border-right: 1px solid #707070;
    margin-top: 60px;
    &:last-of-type {
      border: none;
    }
  }
  img {
    padding: 10px;
    filter: grayscale(.9);
    transition: all 500ms ease-in-out;
    &:hover {
      filter: grayscale(0);
    }
  }
  .w-60 {
    width: 60% !important;
  }
}

.opp {
  h3 {
    font-size: 1.2rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
  }
  h4 {
    font-size: .9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    a {
      font-size: .9rem;
      // color: #333;
    }
  }
  span {
    font-size: .9rem;
    color: #666;
    display: inline-flex;
    align-items: center;
    margin-right: 1rem;
    svg {
      width: 1rem;
      height: 1rem;
      color: #ccc;
      margin-right: .25rem;
    }
  }
  .button {
    background: black;
    color: white;
    padding: .5rem 1rem;
    font-size: .8rem;
    border-radius: 2rem;
  }
}

#appemail {
  width: 90%;
}